<template>
  <el-row class="displays">
    <el-col :span="24">
      <div class="pages hidden-xs-only">
        <div class="banner">
          <img src="@/assets/images/parallelMeetings/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("registerTable").b }}
          </div>
        </div>
        <div class="tableBox">
          <div class="left">
            <div class="title">
              {{ data.sessionTitle }}
            </div>
            <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j1 }}</div>
              <span class="p" v-html="data.description"></span>
            </div>
            <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j2 }}</div>
              <span class="p" v-html="data.objectives"></span>
            </div>
            <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j3 }}</div>
              <span class="p" v-html="data.conveningOrganizations"></span>
            </div>
            <div class="row" v-if="data.conveningOrganizations1">
              <div class="title1">{{ $t("parallelMeeting").j6 }}</div>
              <span class="p" v-html="data.conveningOrganizations1"></span>
            </div>
            <div class="row" v-if="data.zcdw">
              <div class="title1">{{ $t("parallelMeeting").zcdw }}
                <span v-if="lang">（按英文名称字母排序）</span>
                <span v-else>(Alphabetical order)</span>
              </div>
              <span class="p" v-html="data.zcdw"></span>
            </div>
            <div class="row" v-if="data.zzs">
              <div class="title1">{{ $t("parallelMeeting").zzs }}</div>
              <span class="p" v-html="data.zzs"></span>
            </div>
                        <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j4 }}</div>
              <span class="p" v-html="data.thematicArea"></span>
            </div>
            <div class="row" v-if="data.filt">
              <div class="title1">{{ $t("parallelMeeting").j5 }}</div>
              <div
                class="data"
                v-for="(item,index) in data.filt"
                :key="index"
                @click="
                  open(item.url)
                "
              >
                <img src="../../assets/images/parallelMeetings/xz.png" alt="" />
                {{item.title}}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="nav">
              <span @click="goBack()" style="cursor: pointer">{{
                $t("registerTable").b
              }}</span>
              >
              <span style="color: #8dc63f">{{
                $t("parallelMeeting").details
              }}</span>
            </div>
            <div class="iconList">
              <div class="item">
                <img src="../../assets/images/parallelMeetings/1.png" alt="" />
                <div class="text">
                  <div v-html="data.date"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/2.png" alt="" />
                <div class="text">
                  <div v-html="data.timeAllocation"></div>
                </div>
              </div>
              <div class="item" style="align-items: center;cursor: pointer;" @click="!data.noe?goRoom(data.roomAllocation):''">
                <img src="../../assets/images/parallelMeetings/3.png" alt="" />
                <span style="margin-left: 16px;">{{$t('parallelMeeting').t2}}: </span>
                <div class="text" style="margin: 0 0 0 5px;">
                  <div v-html="data.roomAllocation"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/4.png" alt="" />
                <div class="text">
                  <div v-html="data.email"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/5.png" alt="" />
                <div class="text">
                  <div v-html="data.language"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/6.png" alt="" />
                <div class="text">
                  <div v-html="data.preferredFormat"></div>
                </div>
              </div>
            </div>
            <div
              class="btn"
              v-if="data.liveBroadcast"
              @click="!data.noe?open(data.liveBroadcast):''"
            >
              <span>{{ lang ? "进入直播室" : "Livestream Access" }}</span>
              <img src="../../assets/images/parallelMeetings/btn.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="pages1 hidden-sm-and-up">
        <div class="banner">
          <img src="@/assets/images/parallelMeetings/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("registerTable").b }}
          </div>
        </div>
        <div class="tableBox">
          <div class="right">
            <div class="nav">
              <span @click="goBack()" style="cursor: pointer">{{
                $t("registerTable").b
              }}</span>
              >
              <span style="color: #8dc63f">{{
                $t("parallelMeeting").details
              }}</span>
            </div>
            <div class="iconList">
              <div class="item">
                <img src="../../assets/images/parallelMeetings/1.png" alt="" />
                <div class="text">
                  <div v-html="data.date"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/2.png" alt="" />
                <div class="text">
                  <div v-html="data.timeAllocation"></div>
                </div>
              </div>
              <div class="item" @click="!data.noe?goRoom(data.roomAllocation):''">
                <img src="../../assets/images/parallelMeetings/3.png" alt="" />
                <span style="margin-left: 6px;font-size: 13px;">{{$t('parallelMeeting').t2}}: </span>
                <div class="text">
                  <div v-html="data.roomAllocation"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/4.png" alt="" />
                <div class="text">
                  <div v-html="data.email"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/5.png" alt="" />
                <div class="text">
                  <div v-html="data.language"></div>
                </div>
              </div>
              <div class="item">
                <img src="../../assets/images/parallelMeetings/6.png" alt="" />
                <div class="text">
                  <div v-html="data.preferredFormat"></div>
                </div>
              </div>
            </div>
            <div
              class="btn"
              v-if="data.liveBroadcast"
              @click="!data.noe?open(data.liveBroadcast):''"
            >
              <span>{{ lang ? "进入直播室" : "Livestream Access" }}</span>
              <img src="../../assets/images/parallelMeetings/btn.png" alt="" />
            </div>
          </div>
          <div class="left">
            <div class="title">
              {{ data.sessionTitle }}
            </div>
            <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j1 }}</div>
              <span class="p" v-html="data.description"></span>
            </div>
            <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j2 }}</div>
              <span class="p" v-html="data.objectives"></span>
            </div>
            <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j3 }}</div>
              <span class="p" v-html="data.conveningOrganizations"></span>
            </div>
            <div class="row" v-if="data.conveningOrganizations1">
              <div class="title1">{{ $t("parallelMeeting").j6 }}</div>
              <span class="p" v-html="data.conveningOrganizations1"></span>
            </div>
            <div class="row" v-if="data.zcdw">
              <div class="title1">{{ $t("parallelMeeting").zcdw }}</div>
              <span class="p" v-html="data.zcdw"></span>
            </div>
            <div class="row" v-if="data.zzs">
              <div class="title1">{{ $t("parallelMeeting").zzs }}</div>
              <span class="p" v-html="data.zzs"></span>
            </div>
                        <div class="row">
              <div class="title1">{{ $t("parallelMeeting").j4 }}</div>
              <span class="p" v-html="data.thematicArea"></span>
            </div>
            <div class="row" v-if="data.filt">
              <div class="title1">{{ $t("parallelMeeting").j5 }}</div>
              <div
                class="data"
                v-for="(item,index) in data.filt"
                :key="index"
                @click="
                  open(item.url)
                "
              >
                <img src="../../assets/images/parallelMeetings/xz.png" alt="" />
                {{item.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      data: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/parallelMeetingDetails") {
        if (this.$route.query.y || this.$route.query.y == 0) {
          this.data =
            this.$t("parallelMeeting").list[this.$route.query.i][
              this.$route.query.t
            ].type1[this.$route.query.y];
        } else {
          this.data =
            this.$t("parallelMeeting").list[this.$route.query.i][
              this.$route.query.t
            ];
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.y || this.$route.query.y == 0) {
      this.data =
        this.$t("parallelMeeting").list[this.$route.query.i][
          this.$route.query.t
        ].type1[this.$route.query.y];
    } else {
      this.data =
        this.$t("parallelMeeting").list[this.$route.query.i][
          this.$route.query.t
        ];
    }
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
        goRoom(i) {
      switch (i) {
        case '933 6665 4754':
          window.open(
            "https://zoom.us/j/93366654754?pwd=WmFuYUlFeHZJaU91TUorUW0zS1FVZz09"
          );
          break;
        case '989 2086 5684':
          window.open(
            "https://zoom.us/j/98920865684?pwd=aUFrWWdxVmZMY1FYOXdRR1VPT0RVdz09"
          );
          break;
        case '928 1506 4455':
          window.open(
            "https://zoom.us/j/92815064455?pwd=ejh1Z1ZQbkNrNEtad3dvTEhkdXBvZz09"
          );
          break;
        case '940 4441 4598':
          window.open(
            "https://zoom.us/j/94044414598?pwd=VlR2N0tuRm1GY2RvN1ZpTDNkTkkxUT09"
          );
          break;
        case '950 0242 3197':
          window.open(
            "https://zoom.us/j/95002423197?pwd=RGtjb1J6N0I3eFZTVk9TWW5DbHdkZz09"
          );
          break;
        case '986 4781 4002':
          window.open(
            "https://zoom.us/j/98647814002?pwd=aHIwa3YwUXZDS3hNQmRicXgyQVFsQT09"
          );
          break;
        case '939 0210 1729':
          window.open(
            "https://zoom.us/j/93902101729?pwd=aVpBaUF0azM0cUpNcm1BL3VYQ0tEdz09"
          );
          break;
        case '961 7631 0060':
          window.open(
            "https://zoom.us/j/96176310060?pwd=NW9jci9iOGMxOXhvTUo3VFkyckFzdz09"
          );
          break;
        case '945 9685 5046':
          window.open(
            "https://zoom.us/j/94596855046?pwd=eERNQjR1WTRsR1U5RmVCYXFPTXhKQT09"
          );
          break;

        default:
          break;
      }
    },
    open(url) {
      window.open(url);
    },
    goBack() {
      this.$router.go(-1);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="stylus" scoped>
.displays {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Pageconter {
  margin: auto;
}

.pages {
  width: 100%;

  .banner {
    width: 100%;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -170px;
      margin-top: -40px;
      width: 340px;
      height: 80px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      line-height: 80px;
      font-weight: 700;
      font-size: 42px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .tableBox {
    max-width: 1400px;
    background: #FFFFFF;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 37px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .left {
      width: 68.7%;
      background: #FCFBF6;
      height: 100%;
      padding: 46px 32px 30px;
      box-sizing: border-box;

      .title {
        font-weight: 700;
        font-size: 32px;
        color: #405E3C;
        line-height: 42px;
        text-align: left;
        margin-bottom: 36px;
      }

      .row {
        text-align: left;
        margin-bottom: 36px;

        .title1 {
          font-size: 20px;
          line-height: 26px;
          color: #8DC63F;
          margin-bottom: 18px;
        }

        p {
          font-size: 16px;
          line-height: 28px;
          color: #333333;
          text-indent: 30px;
        }

        .p {
          font-size: 16px;
          line-height: 28px;
          color: #333333;
        }

        .data {
          width: 370px;
          height: 83px;
          margin-bottom: 10px;
          padding: 0 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background: #F5F5F5;
          font-size: 16px;
          cursor: pointer;

          img {
            width: 50px;
            margin-right: 23px;
          }
        }
      }
    }

    .right {
      width: 27.2%;

      .nav {
        text-align: right;
        font-size: 16px;
      }

      .iconList {
        width: 100%;
        border-top: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        padding: 35px;
        box-sizing: border-box;
        text-align: left;
        margin-top: 124px;

        .item {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 20px;

          img {
            width: 32px;
          }

          .text {
            font-size: 18px;
            line-height: 24px;
            margin-left: 16px;
            margin-top: 4px;
          }
        }
      }

      .btn {
        width: 78%;
        height: 60px;
        background: #405E3C;
        border-radius: 4px;
        margin: auto;
        margin-top: 36px;
        color: #fff;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}

.pages1 {
  width: 100%;

  .banner {
    width: 100%;
    height: 190px;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -25px;
      width: 120px;
      height: 50px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tableBox {
    max-width: 1400px;
    background: #FFFFFF;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    box-sizing: border-box;

    .left {
      width: 100%;
      background: #FCFBF6;
      height: 100%;
      padding: 20px 20px 30px;
      box-sizing: border-box;

      .title {
        font-weight: 700;
        font-size: 15px;
        color: #405E3C;
        line-height: 24px;
        text-align: left;
        margin-bottom: 10px;
      }

      .row {
        text-align: left;
        margin-bottom: 10px;

        .title1 {
          font-size: 15px;
          line-height: 26px;
          color: #8DC63F;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 20px;
          color: #333333;
        }

        .p {
          font-size: 12px;
          line-height: 20px;
          color: #333333;
        }

        .data {
          width: 307px;
          height: 83px;
          padding: 0 16px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background: #F5F5F5;
          font-size: 16px;

          img {
            width: 50px;
            margin-right: 23px;
          }
        }
      }
    }

    .right {
      width: 100%;

      .nav {
        text-align: left;
        font-size: 14px;
        padding: 0 20px;
        box-sizing: border-box;
      }

      .iconList {
        width: 100%;
        border-top: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        padding: 10px 20px;
        box-sizing: border-box;
        text-align: left;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;

        .item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 10px;
          margin-right: 20px;

          img {
            width: 20px;
          }

          .text {
            font-size: 13px;
            margin-left: 6px;
          }
        }
      }

      .btn {
        width: 150px;
        height: 40px;
        background: #405E3C;
        border-radius: 4px;
        margin: auto;
        margin-top: 10px;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>